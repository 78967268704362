var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "area-heand" }, [
    _c("div", { staticClass: "area-statistical" }, [
      _c("div", { staticClass: "area-wen" }, [
        _vm._v(_vm._s(_vm.msg.cate_name))
      ]),
      _c("div", { staticClass: "sorting" }, [
        _vm._v("\n      今日:\n      "),
        _vm.rank.tiezi
          ? _c("span", { staticClass: "num" }, [_vm._v(_vm._s(_vm.rank.tiezi))])
          : _c("span", { staticClass: "num" }, [_vm._v("0")])
      ]),
      _c("div", { staticClass: "sorting" }, [
        _vm._v("\n      主题:\n      "),
        _vm.rank.ztzsk
          ? _c("span", { staticClass: "num" }, [_vm._v(_vm._s(_vm.rank.ztzsk))])
          : _c("span", { staticClass: "num" }, [_vm._v("0")])
      ]),
      _c("div", { staticClass: "sorting" }, [
        _vm._v("\n      排名:\n      "),
        _vm.rank.jrpmsk
          ? _c("span", { staticClass: "num" }, [
              _vm._v(_vm._s(_vm.rank.jrpmsk))
            ])
          : _c("span", { staticClass: "num" }, [_vm._v("0")])
      ])
    ]),
    _c("div", { staticClass: "area-operation" }, [
      _c(
        "div",
        {
          staticClass: "collection",
          style: { color: _vm.isCollection === 1 ? "#ffcc00" : "#ccc" },
          on: { click: _vm.collection }
        },
        [
          _c("a-icon", { attrs: { type: "star", theme: "filled" } }),
          _c("span", [_vm._v("收藏")])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "subscribe",
          style: { color: _vm.isSubscribe === 1 ? "#ffcc00" : "#ccc" },
          on: { click: _vm.subscribe }
        },
        [
          _c("a-icon", { attrs: { type: "heart", theme: "filled" } }),
          _c("span", [_vm._v("订阅")])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }