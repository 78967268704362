<template>
  <div class="list-operation">
    <div class="operation-theme">
      <div class="all" @click="btnAll">全部主题</div>
      <div @click="btnHot">热门</div>
      <div @click="btnHotPost">热帖</div>
      <div @click="btnElite">精华</div>
      <!-- <div class="more">
              更多
              <a-icon type="caret-down" style="color:#fff;" />
      </div>-->
    </div>
    <div class="operation-select">
      <a-checkbox
        @change="onChange"
        :defaultChecked="checked"
        style="color: #fff;font-size: 18px;"
      >{{ pattern }}</a-checkbox>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checked: true,
      pattern: '图片模式',
      id: '',
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  methods: {
    onChange(e) {
      this.$emit('onChange', e);
      if (e.target.checked) {
        this.pattern = '图片模式';
      } else {
        this.pattern = '文字模式';
      }
    },
    btnAll() {
      this.$emit('btnAll');
    },
    btnHot() {
      return this.$request(Apis.forum.RMPOSTRES, { data: { cate_id: this.id } })
        .then(data => {
          let lists = data.data.data.data;

          this.$emit('btnHot', lists);
        })
        .finally(() => {})
        .catch(e => {});
    },
    btnHotPost() {
      return this.$request(Apis.forum.RTPOSTRES, { data: { cate_id: this.id } })
        .then(data => {
          let lists = data.data.data.data;

          this.$emit('btnHotPost', lists);
        })
        .finally(() => {})
        .catch(e => {});
    },
    btnElite() {
      return this.$request(Apis.forum.JHPOSTRES, { data: { cate_id: this.id } })
        .then(data => {
          let lists = data.data.data.data;

          this.$emit('btnElite', lists);
        })
        .finally(() => {})
        .catch(e => {});
    },
  },
};
</script>
<style lang="less" scoped>
.list-operation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ccc;
  .operation-theme {
    display: flex;
    justify-content: left;
    align-items: center;
    color: #ccc;
    div {
      margin-left: 15px;
      font-size: 16px;
      cursor: pointer;
    }
    .all,
    .more {
      font-size: 18px;
      color: #fff;
    }
  }
}
</style>