<template>
  <div class="img-pattren">
    <template v-for="item in lists">
      <div
        class="pattren-item"
        v-if="item.thumb.startsWith('http://jiakucheng.avik-bj.com')"
        :key="item.id"
        @click="btn(item.id)"
      >
        <div class="item-bg">
          <div class="bg" v-if="isivode">
            <video controls preload="none" :src="item.thumb" data-setup="{}" width="100%" height="100%">
              <source :src="item.thumb" type="video/mp4" />
            </video>
          </div>
          <div
            class="bg"
            v-else
            :style="{ backgroundImage: 'url(' + item.thumb + ')', backgroundSize: 'cover' }"
          ></div>
          <div class="holder"></div>
        </div>
        <div class="item-title">{{item.title}}</div>
        <div class="item-details">
          <div class="details-author">{{item.username}}</div>
          <div class="details-number">
            <div>喜欢:{{item.xh}}</div>
            <div>回复:{{item.hf}}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
export default {
  props: {
    pageNum: VueTypes.number.def(1),
  },
  data() {
    return {
      lists: [],
      total: 0,
      id: '',
      isivode: false,
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.getList('', true);
    this.id = this.$route.params.id;
    if (this.id == 70) {
      this.isivode = true;
    }
  },

  methods: {
    getList(list, is) {
      if (is) {
        return this.$request(Apis.forum.POSTRES, { data: { cate_id: this.$route.params.id, page: '' + this.pageNum, num: '9' } }).then(data => {
          let listsid = [];
          this.total = data.data.data.total;

          data.data.data.data.forEach(e => {
            listsid.push(+e.id);
          });
          this.getListsId(listsid, data.data.data.data);
        });
      } else {
        let listsid = [];
        list.forEach(e => {
          listsid.push(+e.id);
        });
        this.getListsId(listsid, list);
      }
    },
    getListsId(listsid, list) {
      return this.$request(Apis.forum.DYTUPIAN, { data: listsid })
        .then(data => {
          let lists = [];
          let dataList = data.data.dytp;
          let imgList = [];
          for (let i = 0; i < list.length; i++) {
            let object = list[i];
            if (object.id) {
              for (let j = 0; j < dataList.length; j++) {
                if (i === j) {
                  object.thumb = dataList[j];
                }
              }
              imgList.push(object);
            }
          }

          lists = list;
          this.lists = imgList;

          lists.forEach(o => {
            if (o.startsWith('http://jiakucheng.avik-bj.com')) {
              imglist.push(o);
            }
          });
          this.$emit('getTotal', imglist.length);
        })
        .catch(e => {})
        .finally(() => {});
    },
    btn(id) {
      this.$router.push('/forum/details/' + id);
    },
  },
};
</script>
<style lang="less" scoped>
.img-pattren {
  display: flex;
  width: 100%;
  margin: 30px auto;
  flex-wrap: wrap;
  .pattren-item {
    width: 33.333%;
    margin-bottom: 30px;
    padding: 0 15px;
    .item-bg {
      width: 100%;
      position: relative;
      background-color: #fff;
      border-radius: 5px;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: inherit;
        overflow: hidden;
        border-radius: 5px;
      }
      .holder {
        pointer-events: none;
        visibility: hidden;
        display: block;
        padding-bottom: 60%;
      }
    }

    .item-title {
      font-size: 16px;
      color: #fff;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 10px 0;
    }
    .item-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .details-author {
        font-size: 16px;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 50%;
      }
      .details-number {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #ccc;
        font-size: 14px;
        width: 60%;
      }
    }
  }
}
</style>