var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forum-uediter" }, [
    _c(
      "div",
      { staticClass: "forum-title" },
      [
        _c(
          "a-select",
          {
            staticStyle: { width: "20%", "margin-right": "30px" },
            attrs: { allowClear: true, placeholder: "选择主题分类" },
            on: { change: _vm.handleChange }
          },
          _vm._l(_vm.plates, function(item) {
            return _c(
              "a-select-option",
              { key: item.id, attrs: { value: item.id } },
              [
                item.id !== 54
                  ? _c("span", [_vm._v(_vm._s(item.cate_name))])
                  : _vm._e()
              ]
            )
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "forum-input" },
          [
            _c("a-input", {
              attrs: { maxLength: _vm.maxLength },
              model: {
                value: _vm.title,
                callback: function($$v) {
                  _vm.title = $$v
                },
                expression: "title"
              }
            }),
            _c("span", [_vm._v("还可输入" + _vm._s(_vm.num) + "个字符")])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "VueUeditorWrap" },
      [
        _c("VueUeditorWrap", {
          attrs: { config: _vm.myConfig },
          on: { ready: _vm.ready },
          model: {
            value: _vm.content,
            callback: function($$v) {
              _vm.content = $$v
            },
            expression: "content"
          }
        }),
        !this.info ? _c("div", { staticClass: "poition" }) : _vm._e(),
        !this.info.id
          ? _c("div", { staticClass: "wen" }, [
              _vm._v("\n      您需要\n      "),
              _c("span", { staticStyle: { color: "#ffcc00" } }, [
                _vm._v("登录")
              ]),
              _vm._v("才能发帖\n    ")
            ])
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "posting", on: { click: _vm.btnOk } }, [
      _vm._v("发表帖子")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }