<template>
  <div class="area-heand">
    <div class="area-statistical">
      <div class="area-wen">{{msg.cate_name}}</div>
      <div class="sorting">
        今日:
        <span class="num" v-if="rank.tiezi">{{rank.tiezi}}</span>
        <span class="num" v-else>0</span>
      </div>
      <div class="sorting">
        主题:
        <span class="num" v-if="rank.ztzsk">{{rank.ztzsk}}</span>
        <span class="num" v-else>0</span>
      </div>
      <div class="sorting">
        排名:
        <span class="num" v-if="rank.jrpmsk">{{rank.jrpmsk}}</span>
        <span class="num" v-else>0</span>
      </div>
    </div>
    <div class="area-operation">
      <div
        class="collection"
        @click="collection"
        :style="{ color: isCollection===1 ? '#ffcc00' : '#ccc' }"
      >
        <a-icon type="star" theme="filled" />
        <span>收藏</span>
      </div>
      <div
        class="subscribe"
        @click="subscribe"
        :style="{ color: isSubscribe===1 ? '#ffcc00' : '#ccc' }"
      >
        <a-icon type="heart" theme="filled" />
        <span>订阅</span>
      </div>
    </div>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
import { mapState, mapMutations } from 'vuex';
export default {
  props: {
    msg: VueTypes.object,
  },
  data() {
    return {
      id: '',
      isCollection: 0,
      isSubscribe: 0,
      rank: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    this.getrank();
    this.getcollection();
    this.getsubscribe();
  },
  computed: {
    ...mapState('user', ['info']),
  },
  methods: {
    //收藏
    getcollection() {
      if (_.isEmpty(this.info)) {
        return;
      }
      return this.$request(Apis.forum.PDLMSC, { data: { token: this.info.token, cate_id: this.id } }).then(data => {
        this.isCollection = data.data.data;
       
      });
    },
    collection() {
      if (_.isEmpty(this.info)) {
        this.$message.warning('请您先去登录');
        return;
      }

      if (this.isCollection === 0) {
        return this.$request(Apis.forum.FENLEISHOUCANG, { data: { token: this.info.token, cate_id: this.id } }).then(data => {
          this.isCollection = 1;
          this.$message.success('收藏成功');
        });
      } else {
        return this.$request(Apis.forum.FENLEIQUXIAO, { data: { token: this.info.token, cate_id: this.id } }).then(data => {
          this.isCollection = 0;
          this.$message.success('取消收藏成功');
        });
      }
    },
    //订阅
    getsubscribe() {
      if (_.isEmpty(this.info)) {
        return;
      }
      return this.$request(Apis.forum.PDLMDY, { data: { token: this.info.token, cate_id: this.id } }).then(data => {
        this.isSubscribe = data.data.data;
      });
    },
    subscribe() {
      if (_.isEmpty(this.info)) {
        this.$message.warning('请您先去登录');
        return;
      }

      if (this.isSubscribe === 0) {
        return this.$request(Apis.forum.FENLEIDINGY, { data: { token: this.info.token, cate_id: this.id } }).then(data => {
          this.isSubscribe = 1;
          this.$message.success('订阅成功');
        });
      } else {
        return this.$request(Apis.forum.FENLEIQUXIAODINGY, { data: { token: this.info.token, cate_id: this.id } }).then(data => {
          this.isSubscribe = 0;
          this.$message.success('取消订阅成功');
        });
      }
    },
    getrank() {
      return this.$request(Apis.forum.JINRISHU, { data: { cate_id: this.id } })
        .then(data => {
          this.rank = data.data;
          
        })
        .catch(e => {})
        .finally(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.area-heand {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
  .area-statistical,
  .area-operation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .area-statistical {
    width: 50%;
    .area-wen {
      color: #ccc;
      font-size: 18px;
      font-weight: 600;
      margin-right: 20%;
    }
    .sorting {
      color: #cccccc;
      margin-right: 10px;
      .num {
        color: #ffcc00;
      }
    }
  }
  .collection,
  .subscribe {
    color: #ccc;
    font-size: 16px;
    cursor: pointer;
  }
  .collection {
    margin-right: 10px;
  }
}
</style>