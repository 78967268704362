<template>
  <div class="font-pattren">
    <div class="pattren-item" v-for="item in lists" :key="item.id">
      <router-link :to="'/forum/details/' + item.id">
        <div class="item-content">
          <div class="item-title">{{item.title}}</div>
          <!-- <div class="item-del" @mouseover="mouseOver(item.id)" @mouseleave="mouseLeave(item.id)">
            x
            <div class="del" v-show="itemId===item.id" @click.native="delItem(item.id)">删除</div>
          </div> -->
          <div class="item-author">
            <span>{{item.cate_name}}</span>
            <span>{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</span>
          </div>
          <div class="item-num">
            <span class="like">{{item.xh}}</span>
            <span class="hf">{{item.hf}}</span>
          </div>
          <div class="item-comment">
            <span v-if="item.hftime==='暂无回复者'">{{item.hfuser}}</span>
            <span v-else>{{item.hfuser}}</span>
            <span v-if="item.hftime==='暂无时间'">{{item.hftime}}</span>
            <span v-else>{{$moment(+item.hftime).format('YYYY-MM-DD HH:mm')}}</span>
          </div>
        </div>
        <div class="holder"></div>
      </router-link>
    </div>
  </div>
</template>
<script>
import VueTypes from 'vue-types';
export default {
  props: {
    pageNum: VueTypes.number.def(1),
  },
  data() {
    return {
      showDel: false,
      lists: [],
      total: 0,
      id: '',
      itemId: '',
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    this.getList('', true);
  },
  methods: {
    mouseOver(id) {
      this.itemId = id;
    },
    // 移出
    mouseLeave(id) {
      this.itemId = '';
    },
    //视觉删除
    delItem(id) {
      this.lists.filter(item => item.id === id);

      
    },
    getList(list, is) {
      if (is) {
        return this.$request(Apis.forum.POSTRES, { data: { cate_id: this.id, page: '' + this.pageNum, num: '9' } }).then(data => {
          this.lists = data.data.data.data;
          this.total = data.data.data.total;
          this.$emit('getTotal', this.total);
        });
      } else {
        this.lists = list;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.font-pattren {
  width: 100%;
  margin: 30px 0;
  .pattren-item {
    width: 100%;
    position: relative;
    margin: 30px 0;
    background-color: #666666;
    border-radius: 10px;
    .item-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: inherit;
      overflow: hidden;
      padding: 0 10px;
      .item-title {
        width:40%;
        font-size: 16px;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item-del {
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        position: relative;
        .del {
          position: absolute;
          width: 40px;
          top: 2px;
          left: -40px;
          color: #ffcc00;
          z-index: 99;
        }
      }
      .item-author,
      .item-num,
      .item-comment {
        width: 20%;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span {
          width: 100%;
          color: #fff;
        }
        .like {
          text-align: center;
          color: #ffcc00;
        }
        .hf{text-align: center;}
      }
    }
    .holder {
      pointer-events: none;
      visibility: hidden;
      display: block;
      padding-bottom: 10%;
    }
  }
}
</style>