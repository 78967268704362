var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "homeCarousel", attrs: { id: "qqq" } },
    [
      _c(
        "a-carousel",
        { attrs: { autoplay: "" } },
        [
          _vm._l(_vm.carouselLlist, function(item) {
            return [
              _c("a", { key: item.id }, [
                _c("div", {
                  staticClass: "homeCarousel-bg",
                  style: {
                    backgroundImage: "url(" + item.thumb + ")",
                    backgroundSize: "cover"
                  }
                })
              ])
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }