<template>
  <div class="forum-uediter">
    <div class="forum-title">
      <a-select
        style="width: 20%;margin-right:30px"
        @change="handleChange"
        :allowClear="true"
        placeholder="选择主题分类"
      >
        <a-select-option v-for="item in plates" :key="item.id" :value="item.id">
          <span v-if="item.id!==54">{{item.cate_name}}</span>
        </a-select-option>
      </a-select>
      <div class="forum-input">
        <a-input v-model="title" :maxLength="maxLength" />
        <span>还可输入{{num}}个字符</span>
      </div>
    </div>
    <div class="VueUeditorWrap">
      <VueUeditorWrap v-model="content" :config="myConfig" @ready="ready" />
      <div class="poition" v-if="!this.info"></div>
      <div class="wen" v-if="!this.info.id">
        您需要
        <span style="color:#ffcc00">登录</span>才能发帖
      </div>
    </div>
    <div class="posting" @click="btnOk">发表帖子</div>
  </div>
</template>
<script>
import VueUeditorWrap from 'vue-ueditor-wrap';
import { mapState, mapMutations } from 'vuex';
export default {
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      title: '',
      maxLength: 10,
      content: '',
      cate_id: 0,
      plates: [],
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: 'http://jiakucheng.avik-bj.com/php/controller.php?action=config',
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: '/static/UEditor/',
      },
    };
  },
  mounted() {
    this.plate();
  },

  computed: {
    ...mapState('user', ['info']),
    num() {
      return this.maxLength - +this.title.length;
    },
  },
  methods: {
    ready(editorInstance) {
      console.log(`编辑器实例${editorInstance.key}: `, editorInstance);
    },
    handleChange(value) {
      console.log(`selected ${value}`);
      this.cate_id = value;
    },
    plate() {
      return this.$request(Apis.mun.PLATE).then(data => {
        let plates = [];
        data.data.data.forEach(item => {
          item.children.forEach(c => {
            plates.push(c);
          });
        });
        this.plates = plates;
      });
    },

    btnOk() {
      if (_.isEmpty(this.info)) {
        this.$message.warning('您还没有登录，请您去登录');
        return;
      }
      if (this.info.shenhe === 1) {
        console.log(this.cate_id);
        console.log(this.title);
        console.log(this.content);
        if (this.cate_id === 0) {
          this.$message.warning('请选择主题分类');
          return;
        }
        if (!this.title) {
          this.$message.warning('请补全标题');
          return;
        }
        if (!this.content) {
          this.$message.warning('请补全内容');
          return;
        }

        // let cn = RegExp(/.cn/);
        // let com = RegExp(/.com/);
        // if (cn.test(this.content) || com.test(this.content)) {
        //   this.$message.warning('禁止写入链接');
        // }
        let data = {
          token: this.info.token,
          title: this.title,
          content: this.content,
          cate_id: this.cate_id,
        };
        return this.$request(Apis.forum.POSTS, { data: data })
          .then(data => {
            this.$message.success('发表成功');
            this.title = '';
            this.content = '';
            this.cate_id = 0;
            this.$emit('updateDeatil');
          })
          .catch(e => {})
          .finally(() => {});
      } else {
        this.$message.warning('后台正在审核您的账号，请等待');
      }
    },
  },
};
</script>
<style lang="less" scoped>
.forum-uediter {
  .forum-title {
    display: flex;
    justify-items: center;
    margin-bottom: 30px;
  }
  .forum-input {
    display: flex;
    justify-items: center;
    width: 80%;
    span {
      color: #ccc;
      width: 30%;
      margin-left: 10px;
      line-height: 2;
    }
  }

  .posting {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    background: #ffcc00;
    color: #fff;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    margin: 30px 0;
    cursor: pointer;
  }
  .VueUeditorWrap {
    position: relative;
    .poition {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #fff;
      opacity: 0.5;
      z-index: 999;
    }
    .wen {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      text-align: center;
      z-index: 1000;
      font-size: 18px;
    }
  }
}
</style>
