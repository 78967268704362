var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.totalNum && _vm.totalNum === 1
    ? _c(
        "div",
        { attrs: { id: "list-item" } },
        [
          _c("a-pagination", {
            attrs: {
              showQuickJumper: "",
              defaultPageSize: 9,
              defaultCurrent: 1,
              total: _vm.totalNum
            },
            on: { change: _vm.onChangePagination }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }