var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "img-pattren" },
    [
      _vm._l(_vm.lists, function(item) {
        return [
          item.thumb.startsWith("http://jiakucheng.avik-bj.com")
            ? _c(
                "div",
                {
                  key: item.id,
                  staticClass: "pattren-item",
                  on: {
                    click: function($event) {
                      return _vm.btn(item.id)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "item-bg" }, [
                    _vm.isivode
                      ? _c("div", { staticClass: "bg" }, [
                          _c(
                            "video",
                            {
                              attrs: {
                                controls: "",
                                preload: "none",
                                src: item.thumb,
                                "data-setup": "{}",
                                width: "100%",
                                height: "100%"
                              }
                            },
                            [
                              _c("source", {
                                attrs: { src: item.thumb, type: "video/mp4" }
                              })
                            ]
                          )
                        ])
                      : _c("div", {
                          staticClass: "bg",
                          style: {
                            backgroundImage: "url(" + item.thumb + ")",
                            backgroundSize: "cover"
                          }
                        }),
                    _c("div", { staticClass: "holder" })
                  ]),
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v(_vm._s(item.title))
                  ]),
                  _c("div", { staticClass: "item-details" }, [
                    _c("div", { staticClass: "details-author" }, [
                      _vm._v(_vm._s(item.username))
                    ]),
                    _c("div", { staticClass: "details-number" }, [
                      _c("div", [_vm._v("喜欢:" + _vm._s(item.xh))]),
                      _c("div", [_vm._v("回复:" + _vm._s(item.hf))])
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }