var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "font-pattren" },
    _vm._l(_vm.lists, function(item) {
      return _c(
        "div",
        { key: item.id, staticClass: "pattren-item" },
        [
          _c("router-link", { attrs: { to: "/forum/details/" + item.id } }, [
            _c("div", { staticClass: "item-content" }, [
              _c("div", { staticClass: "item-title" }, [
                _vm._v(_vm._s(item.title))
              ]),
              _c("div", { staticClass: "item-author" }, [
                _c("span", [_vm._v(_vm._s(item.cate_name))]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$moment(+item.create_time).format("YYYY-MM-DD HH:mm")
                    )
                  )
                ])
              ]),
              _c("div", { staticClass: "item-num" }, [
                _c("span", { staticClass: "like" }, [_vm._v(_vm._s(item.xh))]),
                _c("span", { staticClass: "hf" }, [_vm._v(_vm._s(item.hf))])
              ]),
              _c("div", { staticClass: "item-comment" }, [
                item.hftime === "暂无回复者"
                  ? _c("span", [_vm._v(_vm._s(item.hfuser))])
                  : _c("span", [_vm._v(_vm._s(item.hfuser))]),
                item.hftime === "暂无时间"
                  ? _c("span", [_vm._v(_vm._s(item.hftime))])
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$moment(+item.hftime).format("YYYY-MM-DD HH:mm")
                        )
                      )
                    ])
              ])
            ]),
            _c("div", { staticClass: "holder" })
          ])
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }