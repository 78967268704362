<template>
  <div id="list-item" v-if="totalNum&&totalNum===1">
    <a-pagination
      showQuickJumper
      :defaultPageSize="9"
      :defaultCurrent="1"
      :total="totalNum"
      @change="onChangePagination"
    />
  </div>
</template>
<script>
import VueTypes from 'vue-types';
export default {
  props: {
    totalNum: VueTypes.number.def(0),
  },
  data() {
    return {
      gettotal: 0,
      num: 0,
    };
  },
  watch: {
    totalNum(n) {
      this.totalNum = n;
    },
  },
  mounted() {
    this.getNum();
  },
  methods: {
    getNum() {},
    onChangePagination(pageNumber) {
      let e = pageNumber;

      this.$emit('onChangePagination', e);
    },
  },
};
</script>
<style lang="less">
#list-item {
  .ant-pagination {
    display: flex;
    justify-content: flex-end;
  }
  .ant-pagination-options-quick-jumper {
    color: #fff !important;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #fff !important;
  }
}
</style>